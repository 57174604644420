<div class="modal-content1">
  <div class="modal-header">Upload File</div>
  <div class="modal-body">
    <button *ngIf="showDownloadButton" class="btn btn-info btn-sm mb-3" (click)="downloadTemplate()">
      <i class="fas fa-download"></i> Download Template
    </button>
    <input type="file" (change)="onFileSelected($event)" accept=".xls,.xlsx" />
  </div>
  <div class="modal-footer">
    <div class="form-row w-100">
      <div class="col-md-auto col-auto">
        <button
          class="btn btn-success btn-sm m-right-10"
          (click)="onConfirm()"
          [disabled]="!selectedFile"
        >
          <i class="fas fa-check"></i> {{ 'UPLOAD' | translate }}
        </button>
        <button class="btn btn-danger btn-sm" (click)="onCancel()">
          <i class="fas fa-times-circle"></i> {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
