<div class="modal-content1">
  <div class="modal-header">
      <h5>Upload File Response</h5>
  </div>
  <div class="modal-body">
      <div>
          <h5 class="text-success">Success:</h5>
          <p>Record updated with {{ responseDataSuccess }} rows from the uploaded file.</p>
      </div>

      <div *ngIf="responseDataError && responseDataError.length > 0">
          <h5 class="text-danger d-flex justify-content-between">
              Errors:
              <button class="btn btn-link p-0" (click)="copyToClipboard(responseDataError.join('\n'))">
                  <i class="fas fa-copy"></i>
              </button>
          </h5>
          <ol>
              <li *ngFor="let error of responseDataError">{{ error }}</li>
          </ol>
      </div>

      <div *ngIf="responseDataError && responseDataError.length === 0">
          <h5 class="text-danger">Errors:</h5>
          <p>No errors found.</p>
      </div>
  </div>
  <div class="modal-footer">
    <div class="form-row w-100">
      <div class="col-md-auto col-auto">
        <button class="btn btn-danger btn-sm" (click)="onCancel()">
          <i class="fas fa-times-circle"></i> {{ 'CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
