import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent {
  selectedFile: File | null = null;
  showDownloadButton: boolean;
  downloadPath: string;

  constructor(
    private dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.showDownloadButton = data.showDownloadButton;
    this.downloadPath = data.downloadPath;}

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }
  }

  onConfirm(): void {
    this.dialogRef.close(this.selectedFile);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
  downloadTemplate() {
    const link = document.createElement('a');
    link.href = this.downloadPath;
    link.download = 'template.xlsx';
    link.click();
  }
}
