import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-upload-response-dialog',
  templateUrl: './file-upload-response-dialog.component.html',
  styleUrls: ['./file-upload-response-dialog.component.scss']
})
export class FileuploadresponsedialogComponent {
  responseDataError: string[] = [];
  responseDataSuccess: any;

  constructor(
    private dialogRef: MatDialogRef<FileuploadresponsedialogComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.responseDataSuccess = data.responseData.item1;
    this.responseDataError = Array.isArray(data.responseData.item2)
      ? data.responseData.item2
      : [];
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.toastrService.success('Copied to clipboard');
    }).catch(err => {
      this.toastrService.error('Failed to copy to clipboard');
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
